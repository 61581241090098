<template>
  <v-card style="overflow-y: hidden; height: 300px">
    <div>
      <v-app-bar flat>
        <v-toolbar-title> CSV取込 </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          :disabled="csvData.length <= 0"
          color="primary"
          @click="$emit('import', csvData)"
        >
          取込
        </v-btn>
        <v-btn icon @click="$emit('cancel')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
      <div style="padding: 25px">
        <div class="d-flex justify-space-between">
          <v-btn color="primary">
            <label for="csvFile">ファイルを選択</label>
            <input
              class="d-none"
              type="file"
              accept=".csv"
              id="csvFile"
              @change="handleFileUpload"
            />
          </v-btn>
          <v-btn color="warning" @click="downloadTemplate">
            テンプレートをダウンロード
          </v-btn>
        </div>
        <h1 v-if="csvData.length > 0">ファイル名：{{ fileName }}</h1>
      </div>
    </div>
  </v-card>
</template>

<script>
import { Store } from "@/store/Store.js";
import axios from "axios";

export default {
  data() {
    return {
      fileName: null,
      csvData: [],
    };
  },
  methods: {
    handleFileUpload(event) {
      const file = event.target.files[0];
      if (file && this.isCsvFile(file)) {
        this.fileName = file.name;
        this.readCsvFile(file);
      } else {
        this.fileName = null;
        this.csvData = [];
        Store.dispatch("Error/show", {
          status: 200,
          message: "CSVファイルを選択してください。",
        });
      }
    },
    isCsvFile(file) {
      return file.name.endsWith(".csv");
    },
    readCsvFile(file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const content = e.target.result.trim();
        if (
          !content ||
          content.split("\n").length <= 1 ||
          content.split("\n").every((line) => line.trim() === "")
        ) {
          this.csvData = [];
          Store.dispatch("Error/show", {
            status: 200,
            message: "CSVファイルが空です。",
          });
        } else {
          this.csvData = content.split("\n").map((line) => line.split(","));
        }
      };

      reader.readAsText(file);
    },

    async downloadTemplate() {
      let filename = "users_import_template.csv";
      let apiUrl = `${process.env.VUE_APP_API_BASE_URL}/file/download/${filename}`;

      try {
        const response = await axios.get(apiUrl, {
          responseType: "blob",
          interceptors: {
            request: axios.interceptors.request.use((config) => {
              const GRN = JSON.parse(sessionStorage.getItem("GRN"));
              sessionStorage.getItem("GRN") &&
                (config.headers.Authorization = `${GRN.Login.api_token}`);
              return config;
            }),
          },
        });

        const link = document.createElement("a");
        const url = window.URL.createObjectURL(new Blob([response.data]));
        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.error("Error downloading the file:", error);
        Store.dispatch(
          "Error/show",
          {
            status: 500,
            message: "システムエラー",
          },
          { root: true }
        );
      }
    },
  },
};
</script>
