import Vue from "vue";
import Vuex from "vuex";
import { company } from "../../../api/modules/company";
import { COPORATE_PREFIX_ARR } from "../../../constants/COMPANY";

Vue.use(Vuex);

/**
 * apiを指定
 */
const ENTITY = company;

export const Company = {
  namespaced: true,

  state: {
    data: [],
    pagination: {},
    companyDetail: {},
    companiesHasMachine: [],
  },

  mutations: {
    SET_DATA(state, payload) {
      state.data = payload;
    },
    SET_PAGINATION(state, payload) {
      state.pagination = payload;
    },
    SET_COMPANY_DETAIL(state, payload) {
      state.companyDetail = payload;
    },
    SET_DATA_COMPANIES_HAS_MACHINE(state, payload) {
      state.companiesHasMachine = payload;
    },
  },

  actions: {
    async get({ commit }, payload) {
      const response = await ENTITY.get(payload);
      if (response.hasError) {
        return response;
      }
      const { entries, pagination } = response.data.contents;
      const prefixMap = new Map(
        COPORATE_PREFIX_ARR.map((el) => [el.id, el.name])
      );
      entries.forEach((item) => {
        const { corporate_type, name } = item;
        const prefix = prefixMap.get(corporate_type);
        item.name = prefix
          ? prefix
              .replace("（会社名）", name)
              .replace("（登記名or個人名）", name)
          : name;
      });
      commit("SET_DATA", entries);
      commit("SET_PAGINATION", pagination);
      return response;
    },
    async getCompanyByIds(_context, payload) {
      const response = await ENTITY.getCompanyByIds(payload);
      if (response.hasError) {
        return response;
      }
      const { entries } = response.data.contents;
      const prefixMap = new Map(
        COPORATE_PREFIX_ARR.map((el) => [el.id, el.name])
      );
      entries.forEach((item) => {
        const { corporate_type, name } = item;
        const prefix = prefixMap.get(corporate_type);
        item.name = prefix
          ? prefix
              .replace("（会社名）", name)
              .replace("（登記名or個人名）", name)
          : name;
      });
      return response;
    },
    async delete(_context, payload) {
      return await ENTITY.delete(payload);
    },
    async getCompanyDetail({ commit }, payload) {
      const response = await ENTITY.getCompanyDetail(payload);
      if (response.hasError) {
        return response;
      }
      const { entries } = response.data.contents;
      commit("SET_COMPANY_DETAIL", entries);
      return response;
    },
    async getCompanyIdByCorporateNumber({ commit }, payload) {
      const response = await ENTITY.getCompanyIdByCorporateNumber(payload);
      if (response.hasError) {
        return response;
      }
      const { entries } = response.data.contents;
      commit("SET_COMPANY_DETAIL", entries);
      return response;
    },
    async checkCompanyExits(_context, payload) {
      return await ENTITY.checkCompanyExits(payload);
    },
    async post(_context, payload) {
      return await ENTITY.post(payload);
    },
    async update(_context, payload) {
      return await ENTITY.update(payload);
    },
    async getListCompaniesHasMachine({ commit }) {
      const response = await ENTITY.getListCompaniesHasMachine();
      if (response.hasError) {
        return response;
      }
      const { entries } = response.data.contents;
      commit("SET_DATA_COMPANIES_HAS_MACHINE", entries);
      return response;
    },
  },

  getters: {
    getData: (state) => {
      return state.data;
    },
    getPagination: (state) => {
      return state.pagination;
    },
    getCompanyDetail: (state) => {
      return state.companyDetail;
    },
    getListCompaniesHasMachine: (state) => {
      return state.companiesHasMachine;
    },
  },
};
