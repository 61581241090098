<template>
  <div>
    <DefaultLayout>
      <template #mainHeader>
        <InhousePortalHeader />
      </template>
      <template #page="{ layoutParams }">
        <TableLayout
          :layoutParams="layoutParams"
          :hideFooter="searchParams.pageCount >= searchParams.total_item"
        >
          <template #tableHeader="{ updateHeader }">
            <TableHeader
              ref="tableHeader"
              :pageTitle="PAGE_TITLE"
              :multiRemoveStatus="disableRemoveBtn"
              :updateHeader="updateHeader"
              :addButtonTitle="ADD_BUTTON_TITLE"
              :isInvite="true"
              :isSearch="false"
              :isCsv="true"
              @openRemoveDialog="openRemoveDialog"
              @openInviteDialog="openInviteEmployeeDialog"
              @openItemForm="openNewItemForm"
              @openCsvImport="openCsvImport"
            >
            </TableHeader>
            <SearchFormWrapper>
              <InputText
                class="search width-small"
                placeholder="氏名"
                name="user_name"
                :editable="true"
                :values="searchInputs"
                @onInput="onChangeSearchInputs"
              />
              <SelectWithFilter
                class="search width-middle"
                placeholder="会社名"
                name="company_id"
                :items="COMPANY_ITEM"
                :editable="true"
                :values="searchInputs"
                @onInput="onChangeSearchInputs"
              />
              <InputText
                class="search width-middle"
                placeholder="メールアドレス"
                name="email"
                :editable="true"
                :values="searchInputs"
                @onInput="onChangeSearchInputs"
              />
              <TabSelect
                class="search"
                name="role"
                :items="USER_ROLE"
                :editable="true"
                :values="searchInputs"
                @onInput="onChangeSearchInputs"
              />
              <SwitchInput
                class="search"
                v-if="isAdmin"
                name="invite_flg"
                label="未招待のみ表示"
                :editable="true"
                :values="searchInputs"
                @onInput="onChangeSearchInputs"
              />
              <v-btn color="primary" @click="onSearch"> 検索 </v-btn>
            </SearchFormWrapper>
            <TableSortWrapper v-if="!isAdmin">
              <TableSort
                :values="searchParams"
                :sort_items="SORT_ITEMS"
                sort_item_text="name"
                sort_item_value="id"
                :page_counts_options="PAGE_COUNT_OPTIONS"
                :sort_order_options="SORT_ORDERS"
                :total_item="searchParams.total_item"
                @onInput="onChangeSortParams"
              />
            </TableSortWrapper>
            <TableTab
              v-if="isAdmin"
              :items="COMPANY_CONTRACT"
              name="company_type"
              :values="tabs"
              @onInput="onChangeTableTab"
            >
              <PageManager
                :values="searchParams"
                :page_counts_options="PAGE_COUNT_OPTIONS"
                :total_item="searchParams.total_item"
                :pageCount="searchParams.pageCount"
                @onInput="onChangeSortParams"
              />
            </TableTab>
          </template>
          <template #tableBody="{ tableHeight }">
            <v-data-table
              v-model="selectedItems"
              :headers="TABLE_LABELS"
              :items="items"
              :items-per-page="searchParams.pageCount"
              :height="tableHeight"
              fixed-header
              hide-default-footer
              disable-sort
              sort-by="updatedAt"
              show-select
              noDataText="データがありません。"
              item-key="id"
              @dblclick:row="($event, { item }) => openItemForm(item)"
            >
              <template v-slot:[`item.user_name`]="{ item }">
                <div>
                  {{ item.name }}
                </div>
              </template>
              <template v-slot:[`item.user_role`]="{ item }">
                <div>
                  {{ convertRoleName(item) }}
                </div>
              </template>
              <template v-slot:[`item.email`]="{ item }">
                <div
                  style="
                    display: inline-block;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 100%;
                  "
                >
                  {{ convertEmail(item.email) }}
                </div>
              </template>
              <template v-slot:[`item.invite_flg`]="{ item }">
                <div>
                  {{ getStatusInvite(item.invite_flg) }}
                </div>
              </template>
            </v-data-table>
          </template>
          <template #tableFooter>
            <Pagination
              :current="searchParams.currentPage"
              :total="searchParams.totalPage"
              @pageUpdate="pageUpdate"
            />
          </template>
        </TableLayout>
      </template>
    </DefaultLayout>

    <Popup :dialog="popups.isShowItemForm">
      <UserForm
        :isNewItem="isNewItem"
        @cancel="closeItemForm"
        ref="UserForm"
        :key="popupKey"
        @reOpen="reOpen"
      />
    </Popup>

    <Popup width="500px" :dialog="popups.isShowCsvImportForm">
      <CsvImport @import="importCsvFile" @cancel="closeItemForm" />
    </Popup>

    <Popup width="500px" :dialog="popups.isShowRemoveDialog">
      <ConfirmDialog
        @close="closeRemoveDialog()"
        @yes="removeItems()"
        :isDelete="true"
        :isUserDetails="true"
        title="選択項目の削除"
        text="以下を削除してもよろしいですか？"
        :items="selectedItems"
      />
    </Popup>

    <Popup width="500px" :dialog="popups.isShowInviteEmployeeDialog">
      <ConfirmDialog
        @close="closeInviteDialogForm()"
        @yes="inviteEmployee()"
        :hasDetails="false"
        :isInvite="true"
        :isCenter="false"
        title="ユーザー追加"
        text="招待メールをお送りします。
        メールアドレスが設定されていないユーザーに
        招待メールは送信されません。"
      />
    </Popup>
    <Loader :isLoading="isLoading"></Loader>
  </div>
</template>
<script>
import { Store } from "@/store/Store.js";
import {
  TABLES_PER_PAGE,
  TABLE_SORT_ORDERS,
  COMPANY_TYPE,
  COMPANY_CONTRACT,
} from "@/constants/COMMON";
import { COPORATE_PREFIX_ARR } from "@/constants/COMPANY";
import {
  USER_TABLE_LABELS,
  USER_SORT_ITEMS,
  USER_ROLE,
  INVITE_STATUS,
} from "@/constants/USER";
import { HEADER_MENU_ITEMS_INHOUSE } from "@/constants/GLOBALHEADER";

/**
 * Components
 */
import DefaultLayout from "@/components/layout/DefaultLayout";
import TableLayout from "@/components/layout/TableLayout";
import TableHeader from "@/components/forms/elements/TableHeader";
import Pagination from "@/components/forms/elements/Pagination";
import TableSortWrapper from "@/components/forms/elements/TableSortWrapper";
import TableSort from "@/components/forms/elements/TableSort";
import Popup from "@/components/common/Popup";
import Loader from "@/components/forms/elements/Loader";
import ConfirmDialog from "@/components/dialog/ConfirmDialog";
import InhousePortalHeader from "@/components/globalHeader/InhousePortalHeader";
import UserForm from "@/components/forms/users/UserForm";
import CsvImport from "@/components/forms/users/CsvImport";
import SearchFormWrapper from "@/components/forms/elements/SearchFormWrapper";
import InputText from "@/components/forms/elements/InputText";
import TabSelect from "@/components/forms/elements/TabSelect";
import TableTab from "@/components/forms/elements/TableTab";
import PageManager from "@/components/forms/elements/PageManager";
import SwitchInput from "@/components/forms/elements/SwitchInput";
import SelectWithFilter from "@/components/forms/elements/SelectWithFilter";

// page_title
const PAGE_TITLE = "ユーザー";

// Number of tables per page default
const PAGE_COUNT = 25;

// Number of tables per page option
const PAGE_COUNT_OPTIONS = TABLES_PER_PAGE;

// sort orders
const SORT_ORDERS = TABLE_SORT_ORDERS;

// STORE
const STORE = "Users";

// Table header label
const TABLE_LABELS = USER_TABLE_LABELS;

// Sort element
const SORT_ITEMS = USER_SORT_ITEMS;

// Button add title
const ADD_BUTTON_TITLE = "新規作成";

export default {
  head: {
    title() {
      return { inner: "", separator: " ", complement: PAGE_TITLE };
    },
  },
  components: {
    DefaultLayout,
    TableLayout,
    TableHeader,
    Pagination,
    SearchFormWrapper,
    PageManager,
    InhousePortalHeader,
    Popup,
    Loader,
    ConfirmDialog,
    InputText,
    TableSortWrapper,
    TableSort,
    TabSelect,
    TableTab,
    UserForm,
    CsvImport,
    SelectWithFilter,
    SwitchInput,
  },
  data() {
    return {
      popupKey: 0,
      PAGE_TITLE,
      TABLE_LABELS,
      SORT_ITEMS,
      SORT_ORDERS,
      PAGE_COUNT_OPTIONS,
      ADD_BUTTON_TITLE,
      INVITE_STATUS,
      COPORATE_PREFIX_ARR,
      COMPANY_ITEM: [],
      items: [],
      filteredItems: [],
      selectedItems: [],
      isNewItem: false,
      isLoading: false,
      searchParams: {
        created_at: null,
        pageCount: PAGE_COUNT,
        currentPage: 1,
        totalPage: 1,
        sort: null,
        asc: true,
      },
      popups: {
        isShowItemForm: false,
        isShowCsvImportForm: false,
        isShowRemoveDialog: false,
        isShowInviteEmployeeDialog: false,
      },
      searchInputs: {
        user_name: "",
        company_id: null,
        email: "",
        invite_flg: 0,
        role: [],
      },
      tabs: {
        company_type: null,
      },
    };
  },

  async mounted() {
    //Prevent BrowserのBack、Forward
    history.pushState(null, null, location.href);
    window.onpopstate = function () {
      history.go(1);
    };

    Store.dispatch("GlobalHeader/setInHouseMenu", {
      menuId: HEADER_MENU_ITEMS_INHOUSE.USERS.id,
    });
    Store.dispatch("GlobalHeader/setSite", {
      field_id: null,
    });
    /**
     * (Common)
     * get data table
     */
    this.getItems();
    this.getListCompany();

    /**
     * (Common)
     * Get data and pagination
     */
    this.$watch(
      () => [
        Store.getters[`${STORE}/getData`],
        Store.getters[`${STORE}/getPagination`],
      ],
      (data) => {
        /**
         *  (Common)
         * Set data list table
         */
        this.items = data[0];

        /**
         *  (Common)
         * Pagination update
         */
        let searchParams = { ...this.searchParams };
        searchParams.totalPage = data[1].total;
        searchParams.currentPage = data[1].current;
        searchParams.total_item = data[1].total_item;
        this.searchParams = searchParams;
      },
      {
        immidiate: true,
        deep: true,
      }
    );
    this.$watch(
      () => Store.state.GlobalHeader.selectedSite,
      () => {
        this.onChangeTableTab(this.tabs.company_type);
      },
      {
        deep: true,
      }
    );
  },

  computed: {
    USER_LOGIN() {
      return Store.state.Login.user;
    },
    isAdmin() {
      if (
        (this.USER_LOGIN.company_type == COMPANY_TYPE.OWNER ||
          this.USER_LOGIN.company_type == COMPANY_TYPE.ADMIN) &&
        this.USER_LOGIN.role == USER_ROLE[0].id
      ) {
        this.tabs.company_type = 0;
        return true;
      }
      return false;
    },
    /**
     * (Common)
     * Delete button active / inactive
     * selectedItems.length === 0
     */
    disableRemoveBtn() {
      return this.selectedItems.length === 0;
    },

    apiParams() {
      return {
        company_id: this.searchParams.company_id,
        role: this.searchParams.role,
        user_name: this.searchParams.user_name,
        email: this.searchParams.email,
        invite_flg: this.searchParams.invite_flg,
        data_by_company_type: this.tabs.company_type,
        sort_value: this.searchParams.sort,
        sort_by: this.searchParams.asc ? 1 : 0,
        page_number: this.searchParams.currentPage,
        page_size: this.searchParams.pageCount,
      };
    },

    USER_ROLE() {
      return Object.keys(USER_ROLE).map((key) => {
        return USER_ROLE[key];
      });
    },

    COMPANY_CONTRACT() {
      return Object.keys(COMPANY_CONTRACT).map((key) => {
        return COMPANY_CONTRACT[key];
      });
    },
  },

  methods: {
    onChangeTableTab(id) {
      this.tabs.company_type = id;
      this.getItems();
    },
    async getListCompany() {
      const response = await Store.dispatch(`Company/get`);
      if (!response.hasError) {
        const { entries } = response.data.contents;
        this.COMPANY_ITEM = [...entries];
        this.COMPANY_ITEM.unshift({
          id: null,
          name: "",
        });
      }
    },
    /**
     * (Common)
     * Pagination event
     * @param Number
     */
    pageUpdate(n) {
      let searchParams = { ...this.searchParams };
      searchParams.currentPage = n;
      this.searchParams = searchParams;
      this.getItems();
    },
    /**
     * (Common)
     * onSearch
     */
    onSearch() {
      this.searchParams = { ...this.searchParams, ...this.searchInputs };
      this.searchParams.currentPage = 1;
      this.$nextTick(() => {
        this.selectedItems = [];
      });
      this.getItems();
    },

    /**
     * (Common)
     * onChangeSearchInputs
     * @param {name:String,value:String}
     */
    onChangeSearchInputs({ name, value }) {
      let searchInputs = { ...this.searchInputs };
      searchInputs[name] = value;
      this.searchInputs = searchInputs;
    },

    /**
     * (Common))
     * onChangeSortParams
     * @param {name:String,value:String}
     */
    onChangeSortParams({ name, value }) {
      let searchParams = { ...this.searchParams };
      searchParams[name] = value;
      this.searchParams = searchParams;
      this.getItems();
    },
    openCsvImport() {
      this.popups.isShowCsvImportForm = true;
    },
    async importCsvFile(CsvData) {
      this.isLoading = true;
      const corporateNumber = CsvData[0][0].split(";")[1];

      const checkCorporateNumber =
        !isNaN(corporateNumber) &&
        corporateNumber.toString().trim().length === 13;

      if (!checkCorporateNumber) {
        Store.dispatch("Error/show", {
          status: 200,
          message: "法人番号が正しくありません。",
        });
        this.isLoading = false;
        return;
      }

      const checkCompanyexits = await Store.dispatch(
        "Company/checkCompanyExits",
        corporateNumber
      );

      if (checkCompanyexits.hasError) {
        const { data } = checkCompanyexits.response;
        Store.dispatch("Error/show", {
          status: data.status_code,
          message: data.message,
        });
        this.isLoading = false;
        return;
      }

      const entries = checkCompanyexits.data.contents.entries;

      if (!entries.isExits) {
        Store.dispatch("Error/show", {
          status: 200,
          message: "会社情報がない為、先に登録してください。",
        });
        this.isLoading = false;
        return;
      }

      const data = CsvData.slice(3);

      // メール存在チェック
      for (const line of data) {
        let apiParams = { email: line[5] };
        const checkEmailExits = await Store.dispatch(
          `${STORE}/checkEmailExits`,
          apiParams
        );

        if (checkEmailExits.hasError) {
          const { data } = checkEmailExits.response;
          Store.dispatch("Error/show", {
            status: data.status_code,
            message: data.message,
          });
          this.isLoading = false;
          return;
        }

        const entries = checkEmailExits.data.contents.entries;

        if (entries.isExits) {
          Store.dispatch("Error/show", {
            status: 200,
            message: "既に登録されているメールアドレスがあります。",
          });
          this.isLoading = false;
          return;
        }
      }

      // データ登録
      for (const line of data) {
        const companyId = await Store.dispatch(
          "Company/getCompanyIdByCorporateNumber",
          corporateNumber
        );

        if (companyId.hasError) {
          const { data } = companyId.response;
          Store.dispatch("Error/show", {
            status: data.status_code,
            message: data.message,
          });
          this.isLoading = false;
          return;
        }

        const entries = companyId.data.contents.entries;
        const apiParams = {
          name_sei: line[0],
          name_mei: line[1],
          kana_sei: line[2],
          kana_mei: line[3],
          role: 1,
          email: line[5],
          company_id: entries[0].id,
          question: "らくらく",
          answer: "点検",
          admin_user_flg: 0,
        };

        const result = await Store.dispatch(`${STORE}/post`, apiParams);

        if (result.hasError) {
          const { data } = result.response;
          Store.dispatch("Error/show", {
            status: data.status_code,
            message: data.message,
          });
          this.isLoading = false;
          return;
        }
      }

      Store.dispatch("Toast/show", {
        status: 200,
        message: "CSVファイルを取り込みました。",
      });
      this.closeItemForm();
    },
    openNewItemForm() {
      this.isNewItem = true;
      this.popups.isShowItemForm = true;
    },
    async openItemForm(item) {
      const result = await Store.dispatch(`${STORE}/getUserDetail`, item.id);
      if (!result.hasError) {
        this.isNewItem = false;
        this.popups.isShowItemForm = true;
      } else {
        const { data } = result.response;
        Store.dispatch("Error/show", {
          status: data.status_code,
          message: data.message,
        });
      }
    },
    closeItemForm() {
      this.popups.isShowItemForm = false;
      this.popups.isShowCsvImportForm = false;
      this.getItems();
    },
    reOpen() {
      this.popupKey += 1;
    },
    openRemoveDialog() {
      this.popups.isShowRemoveDialog = true;
    },
    closeRemoveDialog() {
      this.popups.isShowRemoveDialog = false;
      this.$nextTick(() => {
        this.selectedItems = [];
      });
    },
    async getItems() {
      this.isLoading = true;
      await Store.dispatch(`${STORE}/get`, this.apiParams);
      this.isLoading = false;
    },
    async removeItems() {
      const user_ids = this.selectedItems.map((items) => items.id);
      const result = await Store.dispatch(`${STORE}/delete`, { user_ids });
      if (!result.hasError) {
        this.getItems();
        Store.dispatch("Toast/show", {
          status: 200,
          message: "削除しました",
        });
      } else {
        const { data } = result.response;
        Store.dispatch("Error/show", {
          status: data.status_code,
          message: data.message,
        });
      }
      this.closeRemoveDialog();
    },
    convertRoleName(item) {
      return item.role == 0 ? `${USER_ROLE[1].name}` : `${USER_ROLE[0].name}`;
    },
    convertEmail(email) {
      return email?.length > 60 ? email.substring(0, 60) + "•••" : email;
    },
    getStatusInvite(inviteFlg) {
      return this.INVITE_STATUS[
        Object.keys(this.INVITE_STATUS).find(
          (key) => this.INVITE_STATUS[key].value === inviteFlg
        )
      ]?.name;
    },
    openInviteEmployeeDialog() {
      this.popups.isShowInviteEmployeeDialog = true;
    },
    closeInviteDialogForm() {
      this.popups.isShowInviteEmployeeDialog = false;
      this.$nextTick(() => {
        this.selectedItems = [];
      });
    },
    async inviteEmployee() {
      const user_ids = this.selectedItems.map((items) => items.id);
      const result = await Store.dispatch(`${STORE}/inviteUsers`, { user_ids });
      if (!result.hasError) {
        this.closeInviteDialogForm();
        this.getItems();
        Store.dispatch("Toast/show", {
          status: 200,
          message: "招待しました。",
        });
      }
    },
  },
};
</script>
