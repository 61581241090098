<template>
  <div>
    <DefaultLayout>
      <template #mainHeader>
        <SitePortalHeader />
      </template>
      <template #page="{ layoutParams }">
        <TableLayout :layoutParams="layoutParams" :hideFooter="true">
          <template #tableHeader="{ updateHeader }">
            <v-card-title>{{ currentSite.name }} ホーム</v-card-title>
          </template>
          <template #tableBody="{ tableHeight }">
            <div class="d-flex justify-space-between" style="width: 100%">
              <div class="d-flex flex-column" style="width: 50%">
                <div class="group-title">
                  {{ getFormattedDate() }} 作業前点検状況
                </div>
                <div class="d-flex justify-space-between group1">
                  <div class="v-chip">
                    <div class="flex-column chip-content">
                      <div class="content-title">
                        {{
                          loginUser.company_type == 0 ? "共有機" : "自社機械"
                        }}
                      </div>
                      <div class="content">
                        <v-btn
                          text
                          class="number-with-divider"
                          @click="$router.push('/field/inspections')"
                          >{{ data.ownInspection }}</v-btn
                        >
                        <span class="divider">/{{ data.ownTotal }}</span>
                        <span class="unit">件</span>
                      </div>
                    </div>
                  </div>
                  <div v-if="loginUser.company_type == 0" class="v-chip">
                    <div class="flex-column chip-content">
                      <div class="content-title">持込機械</div>
                      <div class="content">
                        <v-btn
                          text
                          class="number-with-divider"
                          @click="
                            $router.push({
                              name: 'Inspections',
                              params: { tab: 3 },
                            })
                          "
                          >{{ data.othersInspection }}</v-btn
                        >
                        <span class="divider">/{{ data.othersTotal }}</span>
                        <span class="unit">件</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex justify-space-between group2">
                  <div class="v-chip">
                    <div class="flex-column chip-content">
                      <div class="content-title">未訂正不備報告</div>
                      <div class="content">
                        <v-btn
                          text
                          class="number"
                          @click="popups.isShowInspectionNG = true"
                          >{{ data.bugs.length }}</v-btn
                        >
                        <span class="unit">件</span>
                      </div>
                    </div>
                  </div>
                  <div class="v-chip">
                    <div class="flex-column chip-content">
                      <div class="content-title">{{ TITLE1 }}</div>
                      <div class="content">
                        <v-btn
                          text
                          class="number"
                          @click="
                            $router.push({
                              name: 'Inspections',
                              params: { tab: 1 },
                            })
                          "
                          >{{ data.monthlyCount }}</v-btn
                        >
                        <span class="unit">件</span>
                      </div>
                    </div>
                  </div>
                  <div class="v-chip">
                    <div class="flex-column chip-content">
                      <div class="content-title">{{ TITLE2 }}</div>
                      <div class="content">
                        <v-btn
                          text
                          class="number"
                          @click="popups.isShowNewMachine = true"
                          >{{ data.newMachines.length }}</v-btn
                        >
                        <span class="unit">件</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-flex" style="width: 50%">
                <div class="table">
                  <div class="content-title">
                    点検実施履歴 {{ inspectionItems.length }} 件
                  </div>
                  <v-data-table
                    :headers="INSPECTIONS_TABLE_LABELS"
                    :items="inspectionItems"
                    :items-per-page="1000"
                    :height="300"
                    fixed-header
                    hide-default-footer
                    disable-sort
                    noDataText="データがありません。"
                  >
                    <template v-slot:[`item.inspection_at`]="{ item }">
                      <div>
                        {{ convertFormatDate(item) }}
                      </div>
                    </template>
                  </v-data-table>
                </div>
              </div>
            </div>
            <div class="d-flex flex-column" style="width: 100%">
              <div class="group-title">機械点検レポート</div>
              <div class="chart">
                <SearchFormWrapper>
                  <SwitchCustom
                    class="search"
                    name="isWeekly"
                    :values="searchInputs"
                    @onInput="onChangeSearchInputs"
                  />
                  <SelectWithFilter
                    class="search width-middle"
                    placeholder="大分類"
                    name="major_classification_id"
                    :items="MAJOR_ITEM"
                    item_text="major_classification"
                    item_value="major_classification_id"
                    :editable="true"
                    :values="searchInputs"
                    @onInput="onChangeSearchInputs"
                  />
                  <SelectWithFilter
                    class="search width-middle"
                    placeholder="機械名"
                    name="middle_classification_id"
                    item_text="middle_classification"
                    item_value="middle_classification_id"
                    :items="MEDIUM_ITEM"
                    :editable="true"
                    :values="searchInputs"
                    @onInput="onChangeSearchInputs"
                  />
                  <SelectWithFilter
                    class="search width-middle"
                    placeholder="仕様・能力"
                    name="machine_id"
                    item_text="machine_name"
                    item_value="machine_id"
                    :items="ABILITY"
                    :editable="true"
                    :values="searchInputs"
                    @onInput="onChangeSearchInputs"
                  />
                </SearchFormWrapper>
                <Bar :chart-options="chartOptions" :chart-data="chartData" />
              </div>
            </div>
          </template>
        </TableLayout>
        <Popup :dialog="popups.isShowInspectionNG">
          <inspectionNG
            :item="data.bugs"
            @close="popups.isShowInspectionNG = false"
          />
        </Popup>
        <Popup :dialog="popups.isShowNewMachine">
          <newMachines
            :item="data.newMachines"
            @close="popups.isShowNewMachine = false"
          />
        </Popup>
      </template>
    </DefaultLayout>
    <Loader :isLoading="isLoading"></Loader>
  </div>
</template>
<script>
import { Store } from "@/store/Store.js";
import { format } from "date-fns";
import { getDateJp } from "@/utils/timeUtil";
import { Bar } from "vue-chartjs/legacy";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";
import zoomPlugin from "chartjs-plugin-zoom";
import { HEADER_MENU_ITEMS_FIELD } from "@/constants/GLOBALHEADER";
import { INSPECTIONS_TABLE_LABELS } from "@/constants/HOME";
ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  zoomPlugin
);

// Components
import InspectionNG from "@/components/forms/fields/home/InspectionNG";
import NewMachines from "@/components/forms/fields/home/NewMachines";
import SitePortalHeader from "@/components/globalHeader/SitePortalHeader";
import DefaultLayout from "@/components/layout/DefaultLayout";
import TableLayout from "@/components/layout/TableLayout";
import TableHeader from "@/components/forms/elements/TableHeader";
import SearchFormWrapper from "@/components/forms/elements/SearchFormWrapper";
import SelectWithFilter from "@/components/forms/elements/SelectWithFilter";
import SwitchCustom from "@/components/forms/elements/SwitchCustom";
import InputText from "@/components/forms/elements/InputText";
import Popup from "@/components/common/Popup";
import Loader from "@/components/forms/elements/Loader";

const TITLE1 = "今月    月例点検実施件数";
const TITLE2 = "今月    新規登録機械数";

const UNIT_CONTENT = {
  WEEk: { id: 0, name: "週間" },
  MONTH: { id: 1, name: "月間" },
};

const UNIT = Object.keys(UNIT_CONTENT).map((key) => {
  return UNIT_CONTENT[key];
});

export default {
  head: {
    title() {
      return { inner: "", separator: " ", complement: "ホーム" };
    },
  },
  components: {
    InspectionNG,
    NewMachines,
    SearchFormWrapper,
    SelectWithFilter,
    SitePortalHeader,
    DefaultLayout,
    TableLayout,
    TableHeader,
    SwitchCustom,
    InputText,
    Popup,
    Loader,
    Bar,
  },
  data() {
    return {
      TITLE1,
      TITLE2,
      INSPECTIONS_TABLE_LABELS,
      UNIT,
      chartData: {
        labels: [],
        datasets: [
          {
            label: "",
            data: [],
            backgroundColor: "rgb(255, 210, 67, 0.5)",
            borderColor: "rgb(255, 210, 67)",
            borderWidth: 1,
            borderRadius: 5,
          },
        ],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            min: 0,
            max: 25,
            title: {
              display: true,
              text: "管理名",
              font: {
                size: 14,
              },
            },
          },
          y: {
            min: 0,
            max: 100,
            ticks: {
              stepSize: 20,
            },
            title: {
              display: true,
              text: "点検件数（件）",
              font: {
                size: 14,
              },
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            backgroundColor: "#fff",
            bodyColor: "#000",
            borderColor: "rgb(255, 210, 67)",
            borderWidth: 1,
            displayColors: false,
            callbacks: {
              label: function (tooltipItem) {
                return `${tooltipItem.raw}件`;
              },
              title: function () {
                return "";
              },
            },
          },
          zoom: {
            pan: {
              enabled: true,
              mode: "x",
              speed: 10,
              threshold: 10,
            },
          },
        },
      },
      MEDIUM_ITEM: [],
      MAJOR_ITEM: [],
      ABILITY: [],
      isLoading: false,
      popups: {
        isShowInspectionNG: false,
        isShowNewMachine: false,
      },
      graphData: [],
      inspectionItems: [],
      data: {
        ownInspection: 0,
        ownTotal: 0,
        othersInspection: 0,
        othersTotal: 0,
        bugs: [],
        monthlyCount: 0,
        newMachines: [],
      },
      searchInputs: {
        major_classification_id: null,
        middle_classification_id: null,
        inspection_date_from: format(
          new Date(new Date().getFullYear(), new Date().getMonth(), 1),
          "yyyy/MM/dd"
        ),
        inspection_date_to: format(new Date(), "yyyy/MM/dd"),
        machine_id: null,
        isWeekly: false,
        sort_value: null,
        sort_by: 1,
      },
    };
  },

  async mounted() {
    Store.dispatch("GlobalHeader/setInHouseMenu", {
      menuId: HEADER_MENU_ITEMS_FIELD.HOME.id,
    });

    if (this.currentSite.field_id) {
      this.initial();
    }

    this.$watch(
      () => Store.state.GlobalHeader.selectedSite,
      (newValue, oldValue) => {
        if (oldValue.field_id && oldValue.oldValue != newValue.field_id) {
          this.initial();
        }
      },
      {
        deep: true,
      }
    );

    this.$watch(
      () => Store.getters["Home/getData"],
      (data) => {
        if (!data) return;
        this.chartData.labels = data.map((entry) => entry.machine_field_name);
        this.chartData.datasets[0].data = data.map(
          (entry) => entry.machine_count
        );
        const maxValue = Math.max(...this.chartData.datasets[0].data);
        let stepSize = Math.ceil(maxValue / 20);
        stepSize = Math.ceil(stepSize / 10) * 10;
        this.chartOptions.scales.y.max =
          Math.max(...data.map((entry) => entry.machine_count)) + 30;
        this.chartOptions.scales.y.ticks.stepSize = stepSize;
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },

  computed: {
    currentSite() {
      return Store.state.GlobalHeader.selectedSite;
    },
    loginUser() {
      return Store.state.Login.user;
    },
    apiParams() {
      return {
        field_id: this.currentSite.field_id,
        inspection_date_from: this.searchInputs.inspection_date_from,
        inspection_date_to: this.searchInputs.inspection_date_to,
        major_classification_id: this.searchInputs.major_classification_id,
        middle_classification_id: this.searchInputs.middle_classification_id,
        machine_id: this.searchInputs.machine_id,
      };
    },
    inspectionInfoParams() {
      return {
        field_id: this.currentSite.field_id,
        date: format(new Date(), "yyyy-MM-dd"),
      };
    },
  },

  methods: {
    initial() {
      this.getSearchData();
      let localState = {};
      localState.major_classification_id =
        localStorage.getItem("major_classification_id") ?? null;
      localState.middle_classification_id =
        localStorage.getItem("middle_classification_id") ?? null;
      localState.machine_id = localStorage.getItem("machine_id") ?? null;

      this.searchInputs.major_classification_id =
        localState.major_classification_id == null
          ? null
          : parseInt(localState.major_classification_id);
      this.searchInputs.middle_classification_id =
        localState.middle_classification_id == null
          ? null
          : parseInt(localState.middle_classification_id);
      this.searchInputs.machine_id =
        localState.machine_id == null ? null : parseInt(localState.machine_id);
      this.getGraphData();
      this.getListInspecResult();
      this.getCountMonthlyInspecResult();
      this.getInspectionsInfo();
      this.getNewMachinesThisMonth();
      this.getInspectionResultNG();
    },
    getFormattedDate() {
      return getDateJp(new Date()).fmMonthDay;
    },
    convertFormatDate(item) {
      return format(new Date(item.inspection_at), "yyyy/MM/dd HH:mm:ss");
    },
    async getSearchData() {
      this.isLoading = true;
      if (!this.currentSite.field_id) return;
      let apiParams = {
        field_id: this.currentSite.field_id,
        inspection_date_from: this.searchInputs.inspection_date_from,
        inspection_date_to: this.searchInputs.inspection_date_to,
      };
      const response = await Store.dispatch(`Home/getSearchData`, apiParams);
      if (!response.hasError) {
        this.MAJOR_ITEM = [...response.data.contents.entries];
        this.MEDIUM_ITEM = [...response.data.contents.entries];
        this.ABILITY = [...response.data.contents.entries];
      }
      this.isLoading = false;
    },
    async getGraphData() {
      this.isLoading = true;
      await Store.dispatch(`Home/getGraphData`, this.apiParams);
      this.isLoading = false;
    },
    async getInspectionsInfo() {
      this.isLoading = true;
      const response = await Store.dispatch(
        `Home/getInspectionsInfo`,
        this.inspectionInfoParams
      );
      if (!response.hasError) {
        const entries = response.data.contents.entries;
        this.data.ownInspection = entries.own_today_inspections;
        this.data.ownTotal = entries.own_total_machines;
        this.data.othersInspection = entries.others_today_inspections;
        this.data.othersTotal = entries.others_total_machines;
      }
      this.isLoading = false;
    },
    async getNewMachinesThisMonth() {
      this.isLoading = true;
      const response = await Store.dispatch(
        `Home/getNewMachinesThisMonth`,
        this.inspectionInfoParams
      );
      if (!response.hasError) {
        const entries = response.data.contents.entries;
        this.data.newMachines = [...entries];
      }
      this.isLoading = false;
    },
    async getInspectionResultNG() {
      this.isLoading = true;
      const response = await Store.dispatch(
        `Home/getInspectionResultNG`,
        this.inspectionInfoParams
      );
      if (!response.hasError) {
        const entries = response.data.contents.entries;
        this.data.bugs = [...entries];
      }
      this.isLoading = false;
    },
    async getCountMonthlyInspecResult() {
      this.isLoading = true;
      const response = await Store.dispatch(
        `Home/getCountMonthlyInspecResult`,
        this.inspectionInfoParams
      );
      if (!response.hasError) {
        const entries = response.data.contents.entries;
        this.data.monthlyCount = entries.monthly_count;
      }
      this.isLoading = false;
    },
    async getListInspecResult() {
      this.isLoading = true;
      const response = await Store.dispatch(
        `Home/getListInspecResult`,
        this.inspectionInfoParams
      );
      if (!response.hasError) {
        this.inspectionItems = [...response.data.contents.entries];
      }
      this.isLoading = false;
    },
    getMiddleClassification(major_classification_id) {
      this.MEDIUM_ITEM = this.graphData.filter(
        (item) => item.major_classification_id === major_classification_id
      );
    },
    getListAbility(major_classification_id, middle_classification_id) {
      this.ABILITY = this.graphData.filter(
        (item) =>
          item.major_classification_id === major_classification_id &&
          item.middle_classification_id === middle_classification_id
      );
    },
    onChangeSearchInputs({ name, value }) {
      let searchInputs = { ...this.searchInputs };
      searchInputs[name] = value;
      this.searchInputs = searchInputs;
      switch (name) {
        case "major_classification_id":
          localStorage.major_classification_id = value;
          break;
        case "middle_classification_id":
          localStorage.middle_classification_id = value;
          break;
        case "machine_id":
          localStorage.machine_id = value;
          break;
      }
      if (name == "isWeekly") {
        let fromDate;
        let toDate;
        let current = new Date();
        if (value) {
          fromDate = new Date(
            current.setDate(current.getDate() - current.getDay())
          );
          toDate = new Date(
            current.setDate(current.getDate() - current.getDay() + 6)
          );
        } else {
          fromDate = new Date(current.setDate(1));
          toDate = new Date();
        }
        this.searchInputs.inspection_date_from = format(fromDate, "yyyy-MM-dd");
        this.searchInputs.inspection_date_to = format(toDate, "yyyy-MM-dd");
        this.getSearchData();
      }
      this.getGraphData();
    },
  },
};
</script>
<style lang="scss" scoped>
.group-title {
  font-size: 24px;
  font-weight: bold;
  margin-left: 50px;
}

.group1 {
  padding: 0 50px;
}

.group2 {
  padding: 0 30px;
}

.chart,
.v-chip,
.table {
  background-color: #fafafa !important;
  border: 2px solid #d3d3d3;
  border-radius: 40px;
}

.table {
  margin-right: 40px;
  padding: 10px;
  width: 100%;
}

.content-title {
  font-size: 20px;
  color: #99999d;
  margin-bottom: 20px;
  text-align: center;
  white-space: pre;
}

.v-chip {
  height: 150px;
  width: 100%;
  margin: 10px;

  .chip-content,
  ::v-deep .v-chip__content {
    width: 100%;
  }

  .content {
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }

  .number-with-divider {
    font-size: 50px !important;
    font-weight: bold;
    margin-bottom: 20px;
    color: #32c6b4;
  }

  .number {
    font-size: 50px !important;
    font-weight: bold;
    color: #32c6b4;
  }

  .v-btn.v-size--default {
    padding: 0px;
  }

  .divider,
  .unit {
    font-size: 16px;
    margin-left: 10px;
    color: #99999d;
  }
}

.chart {
  margin: 0 40px;
  padding: 10px 20px;
}
</style>
