export const INSPECTIONS_TABLE_LABELS = [
  {
    text: "機械名",
    value: "middle_classification",
    align: "left",
    sortable: false,
    width: "30%",
  },
  {
    text: "管理名",
    value: "machine_field_name",
    align: "left",
    width: "20%",
  },
  {
    text: "点検日時",
    value: "inspection_at",
    align: "left",
    width: "20%",
  },
  {
    text: "点検者",
    value: "inspector",
    align: "left",
    sortable: false,
    width: "30%",
  },
];

export const INSPECTIONS_NG_TABLE_LABELS = [
  {
    text: "点検実施者",
    value: "inspector",
    align: "left",
    sortable: false,
    width: "50%",
  },
  {
    text: "点検実施日",
    value: "inspection_at",
    align: "left",
    width: "20%",
  },
  {
    text: "状態",
    value: "result",
    align: "left",
    sortable: false,
    width: "30%",
  },
];

export const NEW_MACHINES_TABLE_LABELS = [
  {
    text: "大分類",
    align: "left",
    value: "major_classification",
    width: "20%",
  },
  {
    text: "機械名",
    value: "middle_classification",
    align: "left",
    width: "20%",
  },
  {
    text: "仕様・能力",
    align: "left",
    value: "name",
    width: "30%",
  },
  {
    text: "所有会社",
    align: "left",
    value: "company_name",
    width: "30%",
  },
];
