<template>
  <div id="table-1">
    <template v-for="page in compRows">
      <div class="container-fluid page-item text-14">
        <div class="row d-flex justify-content-between">
          <div class="col-lg-6 p-10-0" style="max-width: 45%">
            <div class="d-flex justify-content-between">
              <div class="flex-column">
                <div class="col-md-12 p-10-0">
                  <h2>点検表/確認（承認）状況一覧</h2>
                </div>
                <div class="col-md-12 p-10-0 text-CompanyName">
                  <h3>点検会社： {{ getCompanyName() }}</h3>
                </div>
              </div>
              <div class="flex-column" style="border: 1px solid black">
                <div
                  style="
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 30px;
                    font-weight: bold;
                    border-bottom: 1px solid black;
                  "
                >
                  安全部押印欄
                </div>
                <div class="d-flex flex-column">
                  <v-btn
                    style="
                      height: 75px !important;
                      width: 110px;
                      border-bottom: 1px solid black;
                      border-radius: 0px;
                    "
                    @click="addSign1"
                  >
                    <div>
                      <div class="add-sign1">
                        <span>{{ safeSign.sign_sei1 }}</span>
                      </div>
                      <span
                        style="color: red; font-size: 14px; font-weight: normal"
                        >{{ safeSign.sign_date1 }}</span
                      >
                    </div>
                  </v-btn>
                  <v-btn
                    style="
                      height: 75px !important;
                      width: 110px;
                      border-bottom: 1px solid black;
                      border-radius: 0px;
                    "
                    @click="addSign2"
                  >
                    <div>
                      <div class="add-sign2">
                        <span>{{ safeSign.sign_sei2 }}</span>
                      </div>
                      <span
                        style="color: red; font-size: 14px; font-weight: normal"
                        >{{ safeSign.sign_date2 }}</span
                      >
                    </div>
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6 p-0" style="min-width: 55%">
            <div class="col-md-12 p-0 p-12">
              <table class="w-100 table-signal">
                <thead>
                  <tr>
                    <th class="text-bold p-3" colspan="10" style="">
                      元請押印欄
                    </th>
                  </tr>
                </thead>
                <tbody class="h-signature" style="overflow: hidden">
                  <tr>
                    <td v-for="primeApproved in compPrimeApproved">
                      <div v-if="primeApproved">
                        <div class="round-cycle">
                          <span>{{ primeApproved.prime_approve_name }}</span>
                        </div>
                        <span>{{ primeApproved.prime_approve_date }}</span>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="row mt-20">
          <div class="col-md-4"></div>
          <div class="col-md-8 p-0">
            <span class="mb-0 text-bold note-text">
              <i class="note-bg"></i> : 点検結果<span class="text-red">未</span
              >承認 / -：未使用（未点検）/レ：良好 /×：調整または要修理
              /〇：調整または補修
            </span>
          </div>
        </div>
        <div class="row mt-20">
          <div class="table">
            <table class="w-100 table-inspection-result">
              <thead>
                <tr>
                  <th colspan="3" class="cross"></th>
                  <th colspan="31">{{ getMonth() }}</th>
                </tr>
                <tr class="table-row-header">
                  <th class="text-bold table-col-machinename">機械名</th>
                  <th class="text-bold table-col-machinefieldname">管理名</th>
                  <th class="text-bold table-col-acceptnumber">
                    持込機械受理番号
                  </th>
                  <th v-for="i in 31">{{ i }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="i in 20">
                  <th
                    class="text-left"
                    :class="{ 'end-use-bg': checkEndUseFlg(page.rows[i - 1]) }"
                  >
                    {{
                      page.rows[i - 1]
                        ? relateText(page.rows[i - 1].machine_name)
                        : ""
                    }}
                  </th>
                  <th
                    class="text-left"
                    :class="{ 'end-use-bg': checkEndUseFlg(page.rows[i - 1]) }"
                  >
                    {{
                      page.rows[i - 1]
                        ? page.rows[i - 1].machine_field_name
                        : ""
                    }}
                  </th>
                  <th
                    class="text-left"
                    :class="{ 'end-use-bg': checkEndUseFlg(page.rows[i - 1]) }"
                  >
                    <a
                      class="gotoHistoryLink"
                      @click="showInspectionHistory(page.rows[i - 1])"
                    >
                      {{
                        page.rows[i - 1]
                          ? page.rows[i - 1].acceptance_number
                          : ""
                      }}
                    </a>
                  </th>
                  <th
                    :class="{
                      'bg-yellow': customerStatus(page, i, item - 1),
                      'end-use-bg': checkEndUseFlg(page.rows[i - 1]),
                    }"
                    v-for="item in 31"
                  >
                    {{
                      page.rows[i - 1]
                        ? page.rows[i - 1].check_status[item - 1]
                        : ""
                    }}
                  </th>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6"></div>
          <div class="col-md-6">
            <p class="mb-0 text-bold">
              点検会社は管理する点検済み機械の点検状況を確認・承認すること<br />確認が済んでいないものは速やかに確認すること
            </p>
          </div>
        </div>
      </div>
    </template>
    <Popup :dialog="popups.isShowLogForm">
      <MachineSitesInspectionHistory
        :item="logData"
        :date="getMonth()"
        @close="popups.isShowLogForm = false"
      />
    </Popup>
  </div>
</template>

<script>
import { Store } from "@/store/Store.js";
import { format } from "date-fns";

// Components
import Popup from "@/components/common/Popup";
import MachineSitesInspectionHistory from "@/components/forms/fields/fieldMachines/MachineSitesInspectionHistory";

export default {
  name: "TemplateGuildDance",
  components: {
    Popup,
    MachineSitesInspectionHistory,
  },
  data() {
    return {
      safeSign: {
        sign_sei1: "",
        sign_user_id1: null,
        sign_date1: "",
        sign_sei2: "",
        sign_user_id2: null,
        sign_date2: "",
      },
      logData: {
        id: null,
        middle_classification: null,
        machine_name: null,
        machine_field_name: null,
      },
      popups: {
        isShowLogForm: false,
      },
    };
  },
  props: {
    listInspectionApprovalStatus: {
      type: Array,
      default: [],
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.resize("round-cycle");
    });
    this.$watch(
      () => this.listInspectionApprovalStatus,
      () => {
        this.safeSign.sign_user_id1 = this.getSafeSignlId1();
        this.safeSign.sign_sei1 = this.getSafeSignName1();
        this.safeSign.sign_date1 = this.getSafeSignDate1();
        this.safeSign.sign_user_id2 = this.getSafeSignlId2();
        this.safeSign.sign_sei2 = this.getSafeSignName2();
        this.safeSign.sign_date2 = this.getSafeSignDate2();
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  computed: {
    compRows() {
      return this.listInspectionApprovalStatus[0].pages ?? [];
    },
    compPrimeApproved() {
      let listPrimeApproveNames =
        this.listInspectionApprovalStatus[0].prime_approved_names ?? [];
      let listPrimeApproveDates =
        this.listInspectionApprovalStatus[0].prime_approved_dates ?? [];
      let listPrimeApprove = [];
      if (listPrimeApproveNames.length > 0) {
        for (let i = 0; i < listPrimeApproveNames.length; i++) {
          const primeApprove = {
            prime_approve_name: listPrimeApproveNames[i],
            prime_approve_date: listPrimeApproveDates[i],
          };
          listPrimeApprove = [...listPrimeApprove, primeApprove];
        }
      }
      return listPrimeApprove;
    },
    compPrimeApprovedDates() {
      return this.listInspectionApprovalStatus[0].prime_approved_dates ?? [];
    },
    UserLogin() {
      return Store.state.Login.user;
    },
  },
  methods: {
    addSign1() {
      if (this.safeSign.sign_user_id1 != this.UserLogin.id) {
        return;
      }
      const els = document.querySelectorAll(".add-sign1");
      els.forEach((el) => {
        if (el.classList.contains("round-cycle")) {
          el.classList.remove("round-cycle");
          this.safeSign.sign_sei1 = "";
          this.safeSign.sign_user_id1 = null;
          this.safeSign.sign_date1 = "";
        } else {
          el.classList.add("round-cycle");
          this.safeSign.sign_sei1 = this.UserLogin.name_sei;
          this.safeSign.sign_user_id1 = this.UserLogin.id;
          this.safeSign.sign_date1 = format(new Date(), "MM/dd");
          this.$nextTick(() => {
            this.resize("round-cycle");
          });
        }
      });
      this.$emit("updateSafeSignId", {
        id: this.safeSign.sign_user_id1,
        isSecond: false,
      });
    },
    addSign2() {
      if (this.safeSign.sign_user_id2 != this.UserLogin.id) {
        return;
      }
      const els = document.querySelectorAll(".add-sign2");
      els.forEach((el) => {
        if (el.classList.contains("round-cycle")) {
          el.classList.remove("round-cycle");
          this.safeSign.sign_sei2 = "";
          this.safeSign.sign_user_id2 = null;
          this.safeSign.sign_date2 = "";
        } else {
          el.classList.add("round-cycle");
          this.safeSign.sign_sei2 = this.UserLogin.name_sei;
          this.safeSign.sign_user_id2 = this.UserLogin.id;
          this.safeSign.sign_date2 = format(new Date(), "MM/dd");
          this.$nextTick(() => {
            this.resize("round-cycle");
          });
        }
      });
      this.$emit("updateSafeSignId", {
        id: this.safeSign.sign_user_id2,
        isSecond: true,
      });
    },
    showInspectionHistory(item) {
      this.logData = {
        id: item.machine_field_id,
        middle_classification: item.machine_name,
        machine_name: item.features_ability,
        machine_field_name: item.machine_field_name,
      };
      this.popups.isShowLogForm = true;
    },
    relateText(str, length = 30) {
      if (str.length <= length) {
        return str;
      }
      str = str.slice(0, length);
      return `${str}...`;
    },
    resize(className) {
      let els = document.querySelectorAll(`.${className}`);
      els.forEach((el) => {
        let span = el.querySelector("span");
        if (span.offsetHeight > el.offsetHeight) {
          let currentFontSize =
            Math.floor(el.offsetHeight / span.innerText.length) || 1;
          if (currentFontSize < 10) {
            span.style.fontSize = `10px`;
            span.style.transform = `scale(0.${currentFontSize})`;
          } else {
            span.style.fontSize = `${currentFontSize}px`;
          }
        }
      });
    },
    customerStatus(Page, i, key) {
      if (Page.rows[i - 1]) {
        if (Page.rows[i - 1].approve_status[key] == "0") {
          return true;
        }
      }
      return false;
    },
    checkEndUseFlg(item) {
      if (item?.end_use_flg == 1) {
        return true;
      }
      return false;
    },
    getCompanyName() {
      return this.listInspectionApprovalStatus[0].company_name ?? "";
    },
    getSafeSignlId1() {
      return this.listInspectionApprovalStatus[0].safe_sign_user_id1 ?? "";
    },
    getSafeSignName1() {
      return this.listInspectionApprovalStatus[0].safe_sign_name1 ?? "";
    },
    getSafeSignDate1() {
      return this.listInspectionApprovalStatus[0].safe_sign_at1 ?? "";
    },
    getSafeSignlId2() {
      return this.listInspectionApprovalStatus[0].safe_sign_user_id2 ?? "";
    },
    getSafeSignName2() {
      return this.listInspectionApprovalStatus[0].safe_sign_name2 ?? "";
    },
    getSafeSignDate2() {
      return this.listInspectionApprovalStatus[0].safe_sign_at2 ?? "";
    },
    getMonth() {
      return this.listInspectionApprovalStatus[0].month ?? "";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/themes.scss";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.table {
  width: 100%;
  overflow: hidden;
}
table {
  border-collapse: collapse;
}
table td,
table th {
  border: 1px solid;
  min-width: 15px;
}
table tr {
  height: 25px;
}
.text-red {
  color: red;
}
.text-bold {
  font-weight: bold;
}
.text-14 {
  font-size: 14px;
}
.p-0 {
  padding: 0px;
}
.p-3 {
  padding: 3px;
}
.p-10-0 {
  padding: 10px 0px;
}
.p-12 {
  padding: 12px;
}
.mt-20 {
  margin-top: 20px;
}
.w-100 {
  width: 100%;
}
.page-item {
  padding-bottom: 10px !important;
  border-bottom: 20px solid $border_color;
}
.page-item:last-child {
  border: none !important;
}
.container-fluid {
  padding: 0 30px;
  margin-top: 30px;
}
.container-fluid:first-child {
  padding: 0 30px;
  margin-top: 0px;
}
.row {
  display: flex;
  flex-wrap: wrap;
  position: relative;
}
table .cross {
  background-image: linear-gradient(
    to top right,
    transparent calc(50% - 1px),
    #000,
    transparent calc(50% + 1px)
  );
}
.box {
  border: 1px solid;
  width: 100%;
  height: 130px;
}
.h-signature {
  height: 150px;
  border: 1px solid black;
}
.note-text {
  display: flex;
  font-size: 15px !important;
  width: fit-content !important;
}
.note-text .note-bg {
  width: 20px;
  height: 20px;
  background: yellow;
  margin-right: 3px;
}
.bg-yellow {
  background: yellow;
}
.end-use-bg {
  background: $disable_color;
}
.table-col-machinename {
  width: 220px;
}
.table-col-machinefieldname {
  width: 170px;
}
.table-col-acceptnumber {
  width: 130px;
}
.table-inspection-result {
  font-size: 9pt;
  tbody {
    tr {
      height: 20px;
    }
    th {
      font-weight: bold;
      line-height: 1px;
      white-space: nowrap;
    }
  }
}
.table-row-header {
  border-bottom: inset 2px black !important;
}
.table-signal {
  tbody {
    td {
      padding: 0 5px;
      text-align: center;
      border: solid 0px black;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    tr {
      display: flex;
      flex-wrap: wrap;
    }
  }
}
.table-signal tbody td > div {
  margin: 0px -1px 0px 0px;
}
.table-signal tbody td span {
  color: red;
}
.round-cycle {
  display: flex;
  height: 55px !important;
  width: 45px !important;
  border: solid 2px red;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  writing-mode: vertical-lr;
  color: red;
  font-weight: bold;
  overflow: hidden;
}
.round-cycle span {
  white-space: nowrap;
  padding: 0px;
}
.text-CompanyName {
  width: 380px;
}
.gotoHistoryLink {
  color: $link_color;
}
</style>
