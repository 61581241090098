<template>
  <FormDialogLayout>
    <template #header>
      <v-app-bar flat>
        <v-toolbar-title>今月の新規登録機械一覧</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
    </template>
    <template #main="{ params }">
      <div>
        <v-data-table
          :headers="NEW_MACHINES_TABLE_LABELS"
          :items="items"
          hide-default-footer
          disable-sort
          fixed-header
          :height="params.mainHeight - 50"
          :items-per-page="items.length"
          noDataText="データがありません。"
          outlined
        >
          <template v-slot:[`item.result`]="{ item }">
            <div style="font-size: 25px">
              {{ handleResult(item.result) }}
            </div>
          </template>
          <template v-slot:[`item.inspection_at`]="{ item }">
            <div>
              {{ convertFormatDate(item) }}
            </div>
          </template>
        </v-data-table>

        <Popup width="500px" :dialog="popups.isShowConfirmDialog">
          <ConfirmDialog
            @close="popups.isShowConfirmDialog = false"
            @yes="$emit('close')"
            :isClose="true"
            :isCenter="false"
            title="フォームを閉じる確認"
            text="フォームを閉じますがよろしいですか？
          入力内容は保存されません。
  
          このページから移動してもよろしいですか？"
          />
        </Popup>
      </div>
    </template>
  </FormDialogLayout>
</template>
<script>
import { format } from "date-fns";
import { RESULT_STATUS } from "@/constants/INSPECTION";
import { NEW_MACHINES_TABLE_LABELS } from "@/constants/HOME";

// Components
import Popup from "@/components/common/Popup.vue";
import FormDialogLayout from "@/components/layout/FormDialogLayout";
import ConfirmDialog from "@/components/dialog/ConfirmDialog";

export default {
  components: {
    Popup,
    ConfirmDialog,
    FormDialogLayout,
  },
  data() {
    return {
      NEW_MACHINES_TABLE_LABELS,
      popups: { isShowConfirmDialog: false },
      items: [],
    };
  },
  props: {
    item: Array,
  },
  mounted() {
    this.$watch(
      () => this.item,
      (data) => {
        this.items = [...data];
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  computed: {},
  methods: {
    convertFormatDate(item) {
      return format(new Date(item.inspection_at), "yyyy/MM/dd HH:mm:ss");
    },
    handleResult(result) {
      let rs = "×";
      if (result == RESULT_STATUS.USE_AFTER_REPAIR) {
        rs = "●";
      } else if (result == RESULT_STATUS.GOOD) {
        rs = "レ";
      }
      return rs;
    },
  },
};
</script>
